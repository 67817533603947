/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  FC,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { isSafariDesktop } from 'utils/browser';
import { useTranslation } from 'react-i18next';
import usePrevious from 'hooks/usePrevious';
import IconButton from 'components/theme/IconButton/IconButton';
import IconPlay from 'assets/icon-play';
import IconPause from 'assets/icon-pause';
import IconMuted from 'assets/icon-muted';
import IconUnmuted from 'assets/icon-unmuted';
import { Wrapper, Video } from './styled';

type Props = {
  videoUrl: string;
  coverUrl?: string;
  play?: boolean;
  loop?: boolean;
  showPlayPauseButton?: boolean;
  showMuteButton?: boolean;
  description?: string;
  className?: string;
  customCss?: string;
  onVideoEnd?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onVideoLoaded?: () => void;
};

const MinimalVideoPlayer: FC<Props> = ({
  videoUrl,
  coverUrl,
  play = false,
  loop = false,
  showPlayPauseButton = true,
  showMuteButton = true,
  description = '',
  className,
  customCss,
  onVideoEnd,
  onPlay,
  onPause,
  onVideoLoaded,
}) => {
  const { t } = useTranslation();
  const isDesktopsSafariBrowser = useMemo(() => isSafariDesktop(), []);
  const [muted, setMuted] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const previousPlay = usePrevious(play);
  const [isPlaying, setIsPlaying] = useState(play);
  const [callbackAdded, setCallbackAdded] = useState(false);

  useEffect(() => {
    if (videoRef.current && onVideoLoaded && !callbackAdded) {
      setCallbackAdded(true);
      videoRef.current.onloadeddata = onVideoLoaded;
      if (isDesktopsSafariBrowser) {
        setTimeout(() => {
          if (videoRef.current) {
            videoRef.current.play();
          }
          setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.pause();
            }
          }, 16);
        }, 16);
      }
    }
  }, [onVideoLoaded, isDesktopsSafariBrowser, callbackAdded]);

  const pauseVideo = useCallback(
    (triggerCallback = true) => {
      setIsPlaying(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
      if (triggerCallback && onPause) {
        onPause();
      }
    },
    [videoRef, onPause],
  );

  const playVideo = useCallback(
    (triggerCallback = true) => {
      setIsPlaying(true);
      if (videoRef.current) {
        videoRef.current.play();
      }
      if (triggerCallback && onPlay) {
        onPlay();
      }
    },
    [videoRef, onPlay],
  );

  const muteVideo = useCallback(() => {
    setMuted(true);
  }, []);

  const unmuteVideo = useCallback(() => {
    setMuted(false);
  }, []);

  useEffect(() => {
    if (typeof previousPlay === 'undefined' || previousPlay !== play) {
      if (play) {
        playVideo(false);
      } else {
        pauseVideo(false);
      }
    }
  }, [play, previousPlay, playVideo, pauseVideo]);

  const handleVideoEnd = useCallback(() => {
    if (!loop) {
      pauseVideo();
    }
    if (onVideoEnd) {
      onVideoEnd();
    }
  }, [loop, pauseVideo, onVideoEnd]);

  return (
    <Wrapper className={className} customCss={customCss}>
      <p
        id={`video-description-${videoUrl}`}
        className="sr-only"
        style={{ display: 'none' }}
      >
        {description}. {t('theFollowingVideoDoesNotContainAnyAudio')}
      </p>
      <Video
        ref={videoRef}
        playsInline
        controls={false}
        muted={muted}
        loop={loop}
        src={videoUrl}
        poster={coverUrl}
        onEnded={handleVideoEnd}
        preload="metadata"
        aria-describedby={`video-description-${videoUrl}`}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {showPlayPauseButton && (
        <IconButton
          data-testid={`video-${isPlaying ? 'pause' : 'play'}-button`}
          className="play-pause-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isPlaying) {
              pauseVideo();
            } else {
              playVideo();
            }
          }}
          Icon={isPlaying ? IconPause : IconPlay}
        />
      )}
      {showMuteButton && (
        <IconButton
          data-testid={`video-${muted ? 'unmute' : 'mute'}-button`}
          className="mute-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (muted) {
              unmuteVideo();
            } else {
              muteVideo();
            }
          }}
          Icon={muted ? IconMuted : IconUnmuted}
        />
      )}
    </Wrapper>
  );
};

export default MinimalVideoPlayer;
