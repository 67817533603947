/* eslint-disable default-case, consistent-return */
import {
  ListingKind,
  MediaLinkResolution,
  MediaType,
  ProductLine,
  PublicListingSchema,
} from '@kouto/types';
import { z } from 'zod';
import { serializeParams } from 'utils';
import { QUESTION_SCOPES } from 'types/custom-questions';
import {
  Listing,
  FormattedListingMedia,
  FormatterListingMediaImage,
  FormatterListingMediaVideo,
} from 'types/listings';

export type ListingType = z.infer<typeof PublicListingSchema>;

export const getListingProductLine = (listing: ListingType): ProductLine => {
  if (listing.productLine) return listing.productLine;

  switch (listing.kind) {
    case ListingKind.EVENT:
      return ProductLine.ACTIVATE;
    case ListingKind.RESOURCE:
      return ProductLine.RESERVE;
    case ListingKind.EXPERIENCE:
      return listing.hostedBy ? ProductLine.HOST : ProductLine.RESERVE;
  }
};

export const getListingRoute = (
  listing: ListingType,
): {
  pathname: string;
  search: string;
} => {
  let pathname = '';
  let search = '';

  switch (listing.kind) {
    case ListingKind.EVENT:
      pathname = `/event/${listing.id}`;
      if (listing.dates && listing.dates[0]) {
        search = serializeParams({
          preselectedDate: listing.dates[0],
        });
      }
      break;
    case ListingKind.RESOURCE:
      pathname = `/collection/${listing.id}`;
      break;
    case ListingKind.EXPERIENCE:
      pathname = `/e/${listing.slug}`;
  }

  return {
    pathname,
    search,
  };
};

export const getProductPageTitle = ({
  product,
  translate,
}: {
  product?: ProductLine;
  translate: (label: string, options?: Record<string, string>) => string;
}): string => {
  if (!product) return translate('mainLandingPageTitle');

  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateLandingPageTitle');
    case ProductLine.RESERVE:
      return translate('reserveLandingPageTitle');
    case ProductLine.HOST:
      return translate('hostLandingPageTitle');
  }
};

export const getProductCardButtonLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('buyTickets');
    case ProductLine.RESERVE:
      return translate('reserve');
    case ProductLine.HOST:
      return translate('buyTickets');
  }
};

export const getProductCardLabel = (
  product: ProductLine,
  translate: (label: string) => string,
): string => {
  switch (product) {
    case ProductLine.ACTIVATE:
      return translate('activateProductCardTitle');
    case ProductLine.RESERVE:
      return translate('reserveProductCardTitle');
    case ProductLine.HOST:
      return translate('hostProductCardTitle');
  }
};

export const listingShouldGoThroughParticipantsPage = (
  listing?: Listing,
  selectedGroupId?: string,
  selectedExperienceId?: string,
) => {
  let hasCustomQuestions = false;
  let askPartiticpantsInfo = false;
  let addOns = [];

  if (listing) {
    const selectedGroup = listing.resourceGroups?.find(
      (rg) => rg.id === selectedGroupId,
    );

    addOns = [...listing.addOns, ...(selectedGroup?.addOns || [])];

    if (listing.kind === ListingKind.EXPERIENCE) {
      askPartiticpantsInfo = true;
      // hasCustomQuestions = listing.customQuestions.length // TODO there are no customQuestions on listing root at the moment
    }

    if (selectedGroup) {
      const selectedResource = selectedGroup.experiences.find(
        (r) => r.id === selectedExperienceId,
      );
      if (selectedResource) {
        hasCustomQuestions = !!selectedResource.customQuestions.find(
          (q) => !q.config.isDefault && q.config.isActive,
        );
        askPartiticpantsInfo =
          selectedResource.customQuestions[0].config.scope ===
          QUESTION_SCOPES.PARTICIPANT;
      }
    }
  }

  return {
    hasAddOns: addOns.length > 0,
    askPartiticpantsInfo,
    hasCustomQuestions,
  };
};

export const getListingsCoverPreview = (listings: ListingType[]): string => {
  let coverPreview = '';
  listings.some((listing) => {
    coverPreview = getListingCoverPreview(listing);
    return !!coverPreview;
  });
  return coverPreview;
};

export const getListingCoverPreview = (listing: ListingType): string => {
  const mediasWithImages = listing.medias.filter((m) =>
    m.links.find((link) => link.type === MediaType.IMAGE),
  );
  return mediasWithImages.length > 0
    ? getBiggerMediaPreviewLink(mediasWithImages[0])
    : '';
};

export const getBiggerMediaPreviewLink = (
  media: ListingType['medias'][number],
) => {
  const mediaLinks = media.links.filter((l) => l.type === MediaType.IMAGE);
  return (
    mediaLinks.find((l) => l.resolution === MediaLinkResolution.ORIGINAL)
      ?.url ||
    mediaLinks.sort(
      (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
    )?.[0]?.url ||
    ''
  );
};

export const getMediumMediaPreviewLink = (
  media: ListingType['medias'][number],
) => {
  const mediaLinks = media.links.filter((l) => l.type === MediaType.IMAGE);
  return (
    mediaLinks.sort(
      (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
    )?.[Math.floor(mediaLinks.length / 2)]?.url || ''
  );
};

export const getMediumMediaLink = (media: ListingType['medias'][number]) => {
  const mediaLinks = media.links.filter((l) => l.type === media.type);
  return mediaLinks.sort(
    (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
  )[Math.floor(mediaLinks.length / 2)]?.url;
};

export const getBiggerMediaLink = (media: ListingType['medias'][number]) => {
  const mediaLinks = media.links.filter((l) => l.type === media.type);
  return mediaLinks.sort(
    (ml1, ml2) => parseInt(ml2.resolution, 10) - parseInt(ml1.resolution, 10),
  )[0]?.url;
};

const formatMediaImage = (
  media: ListingType['medias'][number],
): FormatterListingMediaImage => {
  return {
    id: media.id,
    type: MediaType.IMAGE,
    kind: media.kind,
    urlDesktop: getBiggerMediaLink(media),
    urlMobile: getMediumMediaLink(media),
  };
};

const formatMediaVideo = (
  media: ListingType['medias'][number],
): FormatterListingMediaVideo => {
  return {
    id: media.id,
    type: MediaType.VIDEO,
    kind: media.kind,
    urlDesktop: getBiggerMediaLink(media),
    urlMobile: getMediumMediaLink(media),
    previewDesktop: getBiggerMediaPreviewLink(media),
    previewMobile: getMediumMediaPreviewLink(media),
  };
};

export const formatListingMedia = (
  medias: ListingType['medias'],
): FormattedListingMedia[] => {
  const availableMedias = structuredClone(medias || [])
    .filter((media) => media.links.some((link) => link.type === media.type))
    .map((media) => ({
      ...media,
      links: media.links.filter(
        (link) => !Number.isNaN(parseInt(link.resolution, 10)),
      ),
    }));

  return availableMedias.flatMap((media) => {
    if (media.type === MediaType.IMAGE) {
      return formatMediaImage(media);
    }
    return formatMediaVideo(media);
  });
};

export const listingMediaIsVideo = (
  media: FormattedListingMedia,
): media is FormatterListingMediaVideo => media.type === MediaType.VIDEO;
