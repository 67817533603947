import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import { Header, P, Heading4 } from 'components/theme/Typography';
import { SkeletonPulseHero } from 'components/theme/Skeleton';
import ResponsiveLayout from 'ResponsiveLayout';
import ResponsiveImage from 'components/common/ResponsiveImage';
import MinimalVideoPlayer from 'components/MinimalVideoPlayer/MinimalVideoPlayer';
import { getBiggerMediaLink } from 'utils/listings';
import { MediaType } from '@kouto/types';

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
`;

const HeroDesktop = ({ settings }) => {
  const heroIntro = get(settings, 'templateElement.hero.intro');
  const heroHeading = get(settings, 'templateElement.hero.heading');
  const heroDescription = get(settings, 'templateElement.hero.description');
  const desktopHeroMedia = get(settings, 'templateElement.hero.desktopMedia');
  const hasSomeText = !!heroIntro || !!heroHeading || !!heroDescription;

  return (
    <div loading={`${settings.loading}`}>
      {!settings.loading ? (
        <WrapperHero
          className="way-home__hero-wrapper"
          data-testid="homepage-hero"
        >
          {!!desktopHeroMedia && desktopHeroMedia.type === MediaType.VIDEO && (
            <MinimalVideoPlayer
              videoUrl={getBiggerMediaLink(desktopHeroMedia)}
              loop
              play
              className="way-home__hero-video-desktop"
              customCss={customVideoCss(hasSomeText)}
            />
          )}

          {!!desktopHeroMedia && desktopHeroMedia.type === MediaType.IMAGE && (
            <>
              {hasSomeText && (
                <ImageOverlay className="way-home__hero-image-overlay" />
              )}
              <ResponsiveImage
                uriObject={{ '1080w': getBiggerMediaLink(desktopHeroMedia) }}
                defaultSize="1080w"
                viewType="large"
                CustomRenderer={HeroImage}
                alt={heroHeading}
              />
            </>
          )}

          {hasSomeText && (
            <HeroText className="way-home__hero-text-wrapper">
              {heroIntro && (
                <HeadingIntro className="way-home__hero-text-heading-intro">
                  {heroIntro}
                </HeadingIntro>
              )}
              {heroHeading && (
                <Headers className="way-home__hero-text-headers">
                  {heroHeading}
                </Headers>
              )}
              {heroDescription && (
                <HeroDescription className="way-home__hero-text-description">
                  {heroDescription}
                </HeroDescription>
              )}
            </HeroText>
          )}
        </WrapperHero>
      ) : (
        <SkeletonPulseHero translucent />
      )}
    </div>
  );
};

HeroDesktop.propTypes = {
  settings: PropTypes.object.isRequired,
};

const HeroMobile = ({ settings }) => {
  const heroHeading = get(settings, 'templateElement.hero.heading');
  const heroDescription = get(settings, 'templateElement.hero.description');
  const heroIntro = get(settings, 'templateElement.hero.intro');
  const mobileHeroMedia = get(settings, 'templateElement.hero.mobileMedia');
  const hasSomeText = !!heroIntro || !!heroHeading || !!heroDescription;

  // if (!heroImageMobile) return null;
  // TODO wrapper specific to mobile
  return (
    <div loading={`${settings.loading}`}>
      {!settings.loading ? (
        <WrapperHero
          className="way-home__hero-wrapper"
          loading={settings.loading}
          data-testid="homepage-hero"
        >
          {!!mobileHeroMedia && mobileHeroMedia.type === MediaType.VIDEO && (
            <MinimalVideoPlayer
              videoUrl={getBiggerMediaLink(mobileHeroMedia)}
              loop
              play
              className="way-home__hero-video-mobile"
              customCss={customVideoCss(hasSomeText)}
            />
          )}

          {!!mobileHeroMedia && mobileHeroMedia.type === MediaType.IMAGE && (
            <>
              {hasSomeText && (
                <ImageOverlay className="way-home__hero-image-overlay" />
              )}
              <ResponsiveImage
                className="way-home__hero-image"
                uriObject={{ '750w': getBiggerMediaLink(mobileHeroMedia) }}
                defaultSize="750w"
                viewType="small"
                CustomRenderer={HeroImage}
                alt={heroHeading}
              />
            </>
          )}

          {hasSomeText && (
            <HeroText className="way-home__hero-text-wrapper">
              {heroIntro && (
                <HeadingIntro className="way-home__hero-text-heading-intro">
                  {heroIntro}
                </HeadingIntro>
              )}
              {heroHeading && (
                <Headers className="way-home__hero-text-headers">
                  {heroHeading}
                </Headers>
              )}
              {heroDescription && (
                <HeroDescription className="way-home__hero-text-description">
                  {heroDescription}
                </HeroDescription>
              )}
            </HeroText>
          )}
        </WrapperHero>
      ) : (
        <SkeletonPulseHero translucent />
      )}
    </div>
  );
};

HeroMobile.propTypes = {
  settings: PropTypes.object.isRequired,
};

const Hero = ({ settings }) => (
  <ResponsiveLayout
    breakPoint={767}
    renderDesktop={() => <HeroDesktop settings={settings} />}
    renderMobile={() => <HeroMobile settings={settings} />}
  />
);

Hero.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default Hero;

const WrapperHero = styled.div`
  width: 100%;
  margin-bottom: 62px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 100 / 40;
  background: var(--way-palette-black-20);

  @media (max-width: 768px) {
    aspect-ratio: 100 / 150;
  }
`;

const HeadingIntro = styled(Heading4)`
  color: var(--way-palette-white-100);
  text-align: center;
`;

const HeroText = styled.div`
  width: 100%;
  max-width: 983px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  z-index: 2;
`;

const HeroDescription = styled(P)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-palette-white-100);
  text-align: center;

  @media (min-width: 992px) {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 29px;
  }
`;

const Headers = styled(Header)`
  padding: 0 20px;
  margin: 0;
  display: block;
  color: var(--way-palette-white-100);
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-weight: normal;
  text-align: center;
  line-height: 44px;
  margin-bottom: 24px;
  font-size: 40px;

  @media (min-width: 768px) {
    line-height: 44px;
    font-size: 44px;
  }
  @media (min-width: 992px) {
    font-size: 72px;
    line-height: 88px;
    margin-bottom: 42px;
  }
`;

const ImageOverlay = styled.div`
  background: var(--way-palette-black-30);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const customVideoCss = (showOverlay) => `
  z-index: 0;

   &::before {
    content: ' ';
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    ${showOverlay ? 'background: var(--way-palette-black-30);' : ''}
  }
`;
