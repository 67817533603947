import styled from 'styled-components';

export const Wrapper = styled.div<{ customCss?: string }>`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;

  & > .play-pause-button {
    bottom: 8px;
    right: 8px;
  }

  & > .mute-button {
    bottom: 8px;
    right: calc(8px + 32px + 8px);

    @media (max-width: 768px) {
      right: calc(8px + 42px + 8px);
    }
  }

  ${({ customCss }) => customCss}
`;

export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0%;
  left: 0%;
  z-index: 10;
`;
