import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile } from 'WindowDimensionProvider';
import { ListingPictureKind, AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import MinimalVideoPlayer from 'components/MinimalVideoPlayer/MinimalVideoPlayer';
import { formatListingMedia, listingMediaIsVideo } from 'utils/listings';

interface Props {
  scrollAnimationDelay: number;
}

const EventCoverMediaDesktop: FC<Props> = ({ scrollAnimationDelay }) => {
  const isMobile = useIsMobile();
  const { isLoading, event } = useEventBookingSession();
  const disableAnimateScroll = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.DISABLE_ACTIVATE_SCROLLING_ANIMATION,
  );

  const eventCoverMedias = useMemo(
    () =>
      formatListingMedia(
        (event?.medias || []).filter(
          (m) => m.kind === ListingPictureKind.COVER,
        ),
      ).splice(0, 3),
    [event?.medias],
  );

  const videoCount = useMemo(() => {
    return eventCoverMedias.filter((media) => listingMediaIsVideo(media))
      .length;
  }, [eventCoverMedias]);

  const [videoIndexes, setVideoIndexes] = useState<number[]>([]); // indexes of eventCoverMedias that are videos
  const [playingVideoIndex, setPlayingVideoIndex] = useState(0); // pointer to the index value inside videoIndexes

  useEffect(() => {
    setVideoIndexes(
      eventCoverMedias
        .map((media, index) => (listingMediaIsVideo(media) ? index : -1))
        .filter((index) => index !== -1),
    );
  }, [eventCoverMedias, videoCount]);

  const onVideoEnd = useCallback(() => {
    if (videoCount > 1) {
      setPlayingVideoIndex((playingVideoIndex + 1) % videoIndexes.length);
    }
  }, [playingVideoIndex, videoCount, videoIndexes]);

  const onVideoManualPlay = useCallback((videoIndex: number) => {
    setPlayingVideoIndex(videoIndex);
  }, []);

  if (isLoading || !event) {
    return (
      <Wrapper shouldStick={!disableAnimateScroll}>
        <MediaWrapper items={1} scrollAnimationDelay={0}>
          <CoverPhotoSkeleton />
        </MediaWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper shouldStick={!disableAnimateScroll}>
      <MediaWrapper
        items={eventCoverMedias.length}
        scrollAnimationDelay={scrollAnimationDelay}
      >
        {eventCoverMedias.map((media, index) => {
          if (listingMediaIsVideo(media)) {
            return (
              <MinimalVideoPlayer
                videoUrl={isMobile ? media.urlMobile : media.urlDesktop}
                coverUrl={isMobile ? media.previewMobile : media.previewDesktop}
                play={index === videoIndexes[playingVideoIndex]}
                loop={videoCount === 1}
                onVideoEnd={onVideoEnd}
                onPlay={() => onVideoManualPlay(index)}
              />
            );
          }
          return (
            <img
              key={media.id}
              src={isMobile ? media.urlMobile : media.urlDesktop}
              alt={event.title}
            />
          );
        })}
      </MediaWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  shouldStick: boolean;
}>`
  width: 100%;
  height: 600px;
  margin-top: 32px;

  ${({ shouldStick }) =>
    shouldStick
      ? `
        position: sticky;
        top: 8px;
      `
      : ''};
`;

const MediaWrapper = styled.div.attrs<{
  items: number;
  scrollAnimationDelay: number;
}>(({ scrollAnimationDelay }) => ({
  style: {
    transform: `scale(${1 - (0.2 * scrollAnimationDelay) / 100})`,
    opacity: 1 - (0.6 * scrollAnimationDelay) / 100,
  },
}))<{
  items: number;
  scrollAnimationDelay: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: center bottom;

  ${({ items }) => {
    if (items === 1) {
      return `
        > * {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      `;
    }
    if (items === 2) {
      return `
        > * {
          width: calc(50% - 8px);
          height: 100%;
          object-fit: cover;
        }
        > *:first-child {
          margin-right: 16px;
        }
      `;
    }

    return `
      > * {
        width: calc(50% - 8px);
        object-fit: cover;
        height: calc(50% - 8px);
        position: absolute;
      }
      > *:first-child {
        margin-right: 16px;
        height: 100%;
      }
      > *:nth-child(2) {
        top: 0px;
        right: 0px;
      }
      > *:nth-child(3) {
        bottom: 0px;
        right: 0px;
      }
    `;
  }};
`;

const CoverPhotoSkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventCoverMediaDesktop;
