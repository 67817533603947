import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
  }

  & > .carousel-arrow-left {
    left: 20px;
  }

  & > .carousel-arrow-right {
    right: 20px;
  }
`;

export const Slider = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    padding: 0px 12px;
    height: 100%;
    width: 100%;
  }

  > div > img,
  > div video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
