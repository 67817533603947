import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';

import { ButtonBlock } from 'components/theme/Button';
import { getLowestSessionsPrice } from 'components/helpers';
import useCustomHistory from 'hooks/use-custom-history';
import useSearchQueryParams from 'hooks/use-search-params';

import { serializeParams } from 'utils';
import { STATUS_FAILURE, STATUS_PENDING } from 'types/app';
import { SkeletonLine } from 'components/theme/Skeleton';

import { useAppState } from 'AppProvider';
import { useHistory } from 'react-router-dom';
import {
  PrimaryColorText,
  ExperienceTitle,
  ExperienceHeadline,
  ExperiencePrice,
} from './Common';

export default function PreviewModal({
  experience,
  currentMonth,
  selectedDate,
  modalPosition,
  onClose,
}) {
  const modalRef = useRef(null);
  const buttonRef = useRef(null);

  const isHostLed = !experience?.isNoHost && experience.kind !== 'resource';
  const { push: customPush } = useCustomHistory();
  const history = useHistory();
  const { searchParams } = useSearchQueryParams();
  const { t: translate } = useTranslation();
  const { sessionPriceTiers, fetchSessionPriceTiersStatus } = useAppState(
    (state) => state.experience,
  );

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  const handleModalKeyDown = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const handleButtonKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onSeeMoreClick();
    }
  };

  const getHostedByText = () => {
    return `${translate('hostedBy')}  ${get(experience, 'hostedBy.firstName')}`;
  };

  const onSeeMoreClick = () => {
    if (experience.collectionId && experience.groupId) {
      if (experience.collection.kind === 'event') {
        history.push(
          `/event/${experience.collectionId}/tickets/${selectedDate}`,
        );
        return;
      }

      if (experience.collection.kind === 'reserve') {
        customPush({
          pathname: `/collection/${experience.collectionId}/group/${experience.groupId}`,
          search: serializeParams({
            date: selectedDate,
            lang: searchParams?.lang,
          }),
        });
        return;
      }
    }
    customPush({
      pathname: `/e/${experience.slug}`,
      search: serializeParams({
        previous: `/calendar`,
        fromDate: currentMonth,
        sessionDate: selectedDate,
        lang: searchParams?.lang,
      }),
    });
  };

  const isFetchingSessionPriceTiers =
    fetchSessionPriceTiersStatus === STATUS_PENDING;

  return (
    <Modal
      ref={modalRef}
      role="dialog"
      aria-label="Experience Preview"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleModalKeyDown}
      modalPosition={modalPosition}
      tabIndex={-1}
    >
      <ExperienceImage src={get(experience, `coverPicture.uri.384w`)} />
      {isHostLed ? (
        <HostedByText className="calendar__experience-popup-hosted-by">
          {getHostedByText()}
        </HostedByText>
      ) : null}
      <Title isNoHost={!isHostLed}>{experience.title}</Title>

      <Headline>
        {experience.collectionId ? (
          <div dangerouslySetInnerHTML={{ __html: experience.headline }} />
        ) : (
          experience.headline
        )}
      </Headline>

      {isFetchingSessionPriceTiers &&
      fetchSessionPriceTiersStatus !== STATUS_FAILURE ? (
        <SkeletonLine
          translucent
          style={{ height: '8px', minWidth: '160px' }}
        />
      ) : (
        <Price>
          {getLowestSessionsPrice(sessionPriceTiers, experience, translate)}
        </Price>
      )}
      <div>
        <AccessibleButton
          ref={buttonRef}
          type="button"
          onClick={onSeeMoreClick}
          onKeyDown={handleButtonKeyDown}
          tabIndex={0}
        >
          {translate('seeMore')}
        </AccessibleButton>
      </div>
    </Modal>
  );
}

PreviewModal.propTypes = {
  experience: PropTypes.object.isRequired,
  currentMonth: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  modalPosition: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

/*
 * if left position of experience item is less than modal width, add
 * 90% of calendar width to left position so it is shown to right of the item
 * Other subtract 90% of the width to show modal just at the left of the item
 */
const getModalLeftPosition = (left) => {
  if (left <= 213) return `${left + 139.5}px`;
  return `${left - 191.7}px`;
};

/*
 * if top position of experience item is less than modal height, return top
 * Otherwise subtract 50% of the modal height to show it on the middle of the item
 */
const getModalTopPosition = (top, bottom) => {
  if (top <= 337) return `${top}px`;
  if (bottom >= 337) return `${top - 300}px`;
  return `${top - 168.5}px`;
};

const Modal = styled.div`
  position: fixed;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 213px;
  height: auto;
  padding: 16px;
  top: ${({ modalPosition }) =>
    getModalTopPosition(modalPosition?.top, modalPosition?.bottom)};
  left: ${({ modalPosition }) => getModalLeftPosition(modalPosition?.left)};
  background: var(--way-palette-white-100);
  box-shadow: var(--way-design-boxShadow-s);
  border: 0.5px solid var(--way-colors-contrastColorShades-80);
  border-radius: 4px;
  z-index: 9999999;
`;

const ExperienceImage = styled.img`
  min-width: 181px;
  max-width: 181px;
  height: 125px;
  object-fit: cover;
  background-color: #eee;
`;

const HostedByText = styled(PrimaryColorText)`
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--way-palette-black-100);
`;

const Title = styled(ExperienceTitle)`
  color: var(--way-palette-black-100);
  margin-top: ${({ isNoHost }) => (isNoHost ? '10px' : '0px')};
`;

const Headline = styled(ExperienceHeadline)`
  color: var(--way-palette-black-100);
`;

const Price = styled(ExperiencePrice)`
  color: var(--way-palette-black-100);
`;

const AccessibleButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: var(--way-colors-primaryColorShades-100);
  color: var(--way-colors-primaryColorContrastShades-100);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: var(--way-colors-primaryColorShades-80);
  }

  &:focus {
    outline: 2px solid var(--way-colors-primaryColorShades-100);
    outline-offset: 2px;
  }
`;
