import React, {
  FC,
  PropsWithChildren,
  useState,
  useRef,
  useMemo,
  useCallback,
  Children,
  isValidElement,
  useEffect,
} from 'react';
import IconButton from 'components/theme/IconButton/IconButton';
import usePrevious from 'hooks/usePrevious';
import IconLeft from 'assets/chevron-left';
import IconRight from 'assets/chevron-right';
import { Wrapper, Slider } from './styled';

type Props = {
  showArrows?: boolean;
  selectedSlideIndex?: number;
  onSlideChange?: (index: number) => void;
};

const MinimalCarousel: FC<PropsWithChildren<Props>> = ({
  showArrows = true,
  selectedSlideIndex = 0,
  onSlideChange,
  children,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(selectedSlideIndex);
  const previousSelectedTabIndex = usePrevious(selectedSlideIndex);
  const childrenCount = useMemo(
    () => Children.toArray(children).filter(Boolean).length,
    [children],
  );
  const canGoLeft = useMemo(() => currentIndex > 0, [currentIndex]);
  const canGoRight = useMemo(
    () => currentIndex < childrenCount - 1,
    [currentIndex, childrenCount],
  );

  const onScroll = useCallback(() => {
    if (sliderRef.current) {
      const newSelectedTabIndex = Math.floor(
        sliderRef.current.scrollLeft / sliderRef.current.clientWidth,
      );
      if (currentIndex !== newSelectedTabIndex) {
        setCurrentIndex(newSelectedTabIndex);
      }
    }
  }, [sliderRef.current, currentIndex]);

  const goLeft = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft - sliderRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  }, [sliderRef.current]);

  const goRight = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft + sliderRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  }, [sliderRef.current]);

  const goToIndex = useCallback(
    (index: number) => {
      if (sliderRef.current) {
        sliderRef.current.scrollTo({
          left: index * sliderRef.current.clientWidth,
          behavior: 'smooth',
        });
      }
    },
    [sliderRef.current],
  );

  useEffect(() => {
    if (onSlideChange) {
      onSlideChange(currentIndex);
    }
  }, [onSlideChange, currentIndex]);

  useEffect(() => {
    if (
      previousSelectedTabIndex !== selectedSlideIndex &&
      selectedSlideIndex !== currentIndex
    ) {
      goToIndex(selectedSlideIndex);
    }
  }, [previousSelectedTabIndex, selectedSlideIndex, currentIndex, goToIndex]);

  const formattedChildren = useMemo(
    () =>
      Children.map(children, (child) =>
        isValidElement(child) ? <div key={child.key}>{child}</div> : null,
      )?.filter((e) => !!e),
    [children],
  );

  return (
    <Wrapper>
      {showArrows && childrenCount > 1 && (
        <>
          <IconButton
            disabled={!canGoLeft}
            className={`carousel-arrow carousel-arrow-left ${
              !canGoLeft ? 'carousel-arrow-disabled' : ''
            }`}
            Icon={IconLeft}
            onClick={goLeft}
          />
          <IconButton
            disabled={!canGoRight}
            className={`carousel-arrow carousel-arrow-right ${
              !canGoLeft ? 'carousel-arrow-disabled' : ''
            }`}
            Icon={IconRight}
            onClick={goRight}
          />
        </>
      )}
      <Slider ref={sliderRef} onScroll={onScroll}>
        {formattedChildren}
      </Slider>
    </Wrapper>
  );
};

export default MinimalCarousel;
